@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700;900&display=swap");
.color-white {
  color: #fff; }

.color-red {
  color: #ea0101; }

.color-black {
  color: #000; }

.color-orange {
  color: #FF9002; }

.color-green {
  color: #26CB26; }

.color-cornflower {
  color: #605AF4; }

.bg-green {
  background-color: #26CB26; }

.bg-orange {
  background-color: #FF9002; }

.border-color-white {
  border-color: #fff; }

* {
  font-weight: inherit;
  padding: 0;
  margin: 0;
  list-style: none;
  text-decoration: none;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

:root {
  --animate-delay: 3.5s; }

.hidden {
  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  /* IE 5-7 */
  filter: alpha(opacity=0);
  /* Netscape */
  -moz-opacity: 0;
  /* Safari 1.x */
  -khtml-opacity: 0;
  /* Good browsers */
  opacity: 0;
  visibility: hidden; }

a:hover {
  color: #fff; }

h1, h2, h3, h4, h5, h6 {
  margin: 0; }

input,
textarea,
select,
button {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }
  input:required, input:invalid,
  textarea:required,
  textarea:invalid,
  select:required,
  select:invalid,
  button:required,
  button:invalid {
    box-shadow: none; }
  input[type="number"],
  textarea[type="number"],
  select[type="number"],
  button[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield; }
  input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button,
  textarea[type="number"]::-webkit-inner-spin-button,
  textarea[type="number"]::-webkit-outer-spin-button,
  select[type="number"]::-webkit-inner-spin-button,
  select[type="number"]::-webkit-outer-spin-button,
  button[type="number"]::-webkit-inner-spin-button,
  button[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none; }
  input::-ms-clear,
  textarea::-ms-clear,
  select::-ms-clear,
  button::-ms-clear {
    display: none;
    width: 0;
    height: 0; }
  input::-ms-reveal,
  textarea::-ms-reveal,
  select::-ms-reveal,
  button::-ms-reveal {
    display: none;
    width: 0;
    height: 0; }

.noSelect {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

html,
body {
  font-family: 'Montserrat', sans-serif;
  height: 100%;
  background-color: #FCFCFC; }
  html.overflow-hidden,
  body.overflow-hidden {
    overflow: hidden;
    touch-action: none;
    -ms-touch-action: none; }

.text-align-center {
  text-align: center; }

.text-align-left {
  text-align: left; }

.text-align-right {
  text-align: right; }

.btn {
  display: inline-block;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -ms-border-radius: 10px;
  -moz-border-radius: 10px;
  letter-spacing: 0.01em;
  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  /* IE 5-7 */
  filter: alpha(opacity=1);
  /* Netscape */
  -moz-opacity: 1;
  /* Safari 1.x */
  -khtml-opacity: 1;
  /* Good browsers */
  opacity: 1;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  @media (hover: hover) {
    .btn:not(.disabled):hover {
      /* IE 8 */
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
      /* IE 5-7 */
      filter: alpha(opacity=0.8);
      /* Netscape */
      -moz-opacity: 0.8;
      /* Safari 1.x */
      -khtml-opacity: 0.8;
      /* Good browsers */
      opacity: 0.8; } }
  .btn-small {
    font-size: 13px;
    line-height: 34px;
    width: 126px; }
  .btn-small {
    font-size: 13px;
    line-height: 36px;
    width: 126px; }
  .btn-medium {
    font-size: 16px;
    line-height: 36px;
    width: 140px; }
  .btn-medium-extra {
    font-size: 16px;
    line-height: 36px;
    width: 370px; }
  .btn-subscribe {
    border: none;
    width: 105px;
    line-height: 30px;
    font-size: 14px;
    font-weight: 600; }
  .btn-checkout {
    border: none;
    width: 230px;
    line-height: 40px;
    font-size: 16px;
    font-weight: 600; }
  .btn.disabled {
    cursor: not-allowed;
    /* IE 8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
    /* IE 5-7 */
    filter: alpha(opacity=0.5);
    /* Netscape */
    -moz-opacity: 0.5;
    /* Safari 1.x */
    -khtml-opacity: 0.5;
    /* Good browsers */
    opacity: 0.5; }

.form-box {
  max-width: 290px;
  width: 100%;
  display: flex;
  flex-wrap: wrap; }
  .form-box-subscribe .form-title {
    margin: 0 0 12px; }
  .form-box-paypal {
    display: block;
    max-width: 100%; }

.form-title {
  width: 100%; }

.field-row {
  display: flex;
  justify-content: space-between; }
  .field-row-half .field-box {
    width: 48%; }
  .field-row-subscribe {
    width: 100%;
    box-shadow: -1px 3px 6px 0 rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: -1px 3px 6px 0 rgba(0, 0, 0, 0.25);
    -moz-box-shadow: -1px 3px 6px 0 rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -ms-border-radius: 8px;
    -moz-border-radius: 8px; }
    .field-row-subscribe button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .field-row-paypal {
    display: block; }

.field-box {
  width: 100%;
  position: relative; }
  .field-box input {
    width: 100%;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 14px;
    color: #000;
    border: 1px solid #fff;
    min-height: 30px;
    padding: 0 10px; }
    .field-box input::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: #CACACA; }
    .field-box input:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #CACACA;
      opacity: 1; }
    .field-box input::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #CACACA;
      opacity: 1; }
    .field-box input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #CACACA; }
    .field-box input.has-error {
      border: 1px solid #ea0101 !important; }
    .field-box input.has-error + .icon svg path {
      fill: #ea0101; }
    .field-box input.with-border {
      border: 1px solid #CECECE; }
    .field-box input.with-icon {
      padding: 0 10px 0 40px; }
  .field-box-subscribe input {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    width: 100%;
    border-right: 0; }
  .field-box-paypal {
    margin-bottom: 60px; }
    .field-box-paypal input {
      min-height: 36px;
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -ms-border-radius: 8px;
      -moz-border-radius: 8px; }
  .field-box .icon {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    left: 12px; }
    .field-box .icon-email svg {
      display: block;
      height: auto;
      width: 17px; }

.message-field-label {
  font-size: 11px;
  bottom: -18px;
  position: absolute; }

.message-error {
  color: #ea0101;
  font-size: 12px; }

.message-success {
  color: #26CB26;
  bottom: -34px; }

.dropdown-transition-enter {
  opacity: 0;
  transform: scale(0.9); }
  .dropdown-transition-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms; }

.dropdown-transition-exit {
  opacity: 1; }
  .dropdown-transition-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms; }

.modal-coupon .ReactModal__Overlay {
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.8) !important; }

.modal-coupon .ReactModal__Content {
  box-sizing: border-box;
  position: relative;
  left: 50% !important;
  right: auto !important;
  bottom: auto !important;
  top: 50% !important;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  width: 90%;
  text-align: center;
  padding: 50px 30px !important;
  border-radius: 12px !important;
  border: 0 !important;
  background: #fff !important;
  box-shadow: 4px 4px 20px 0 rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 4px 4px 20px 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 4px 4px 20px 0 rgba(0, 0, 0, 0.3); }

.modal-coupon h2 {
  font-size: 22px;
  font-weight: 500;
  margin: 0 0 24px; }

.loader {
  display: inline-block;
  width: 20px;
  height: 20px;
  position: absolute;
  border: 2px solid #FF9002;
  top: 50%;
  animation: loader 2s infinite ease; }
  .loader-box {
    position: fixed;
    text-align: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #FCFCFC;
    z-index: 999999; }
    .loader-box.transparent {
      background-color: rgba(252, 252, 252, 0.6); }
  .loader-inner {
    vertical-align: top;
    display: inline-block;
    width: 100%;
    background-color: #FF9002;
    animation: loader-inner 2s infinite ease-in; }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  25% {
    transform: rotate(180deg); }
  50% {
    transform: rotate(180deg); }
  75% {
    transform: rotate(360deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes loader-inner {
  0% {
    height: 0; }
  25% {
    height: 0; }
  50% {
    height: 100%; }
  75% {
    height: 100%; }
  100% {
    height: 0; } }

@media screen and (max-width: 520px) {
  .form-box-subscribe {
    max-width: inherit; } }

header {
  padding: 16px 0;
  background-color: #171058;
  position: fixed;
  width: 100%;
  z-index: 99; }
  header-wrapper {
    max-width: 1040px;
    width: 96%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  header-logo svg {
    display: block;
    width: 200px;
    height: auto; }
  header-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px; }
    header-menu-item a {
      font-weight: 500;
      color: #0B0B0B;
      font-size: 16px;
      letter-spacing: 0.02em; }
      header-menu-item a.active {
        color: #FF9002;
        text-decoration: underline; }
      @media (hover: hover) {
        header-menu-item a:not(.btn):hover {
          color: #FF9002;
          text-decoration: underline; } }
    header-menu-item.button-wrap {
      display: flex; }
    header-menu-button {
      display: none;
      cursor: pointer;
      z-index: 1; }
      header-menu-button i {
        display: block;
        cursor: pointer;
        width: 20px;
        height: 16px;
        position: relative;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out; }
        header-menu-button i span {
          display: block;
          position: absolute;
          height: 2px;
          width: 100%;
          background: #000;
          opacity: 1;
          left: 0;
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
          -webkit-transition: .25s ease-in-out;
          -moz-transition: .25s ease-in-out;
          -o-transition: .25s ease-in-out;
          transition: .25s ease-in-out; }
          header-menu-button i span:nth-child(1) {
            top: 0; }
          header-menu-button i span:nth-child(2) {
            top: 6px; }
          header-menu-button i span:nth-child(3) {
            top: 6px; }
          header-menu-button i span:nth-child(4) {
            top: 12px; }
      header-menu-button.clicked i span:nth-child(1) {
        width: 0;
        left: 50%;
        opacity: 0; }
      header-menu-button.clicked i span:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg); }
      header-menu-button.clicked i span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg); }
      header-menu-button.clicked i span:nth-child(4) {
        width: 0;
        left: 50%;
        opacity: 0; }
  header.menu-open header-logo {
    position: relative;
    z-index: 9; }
  header.menu-open header-menu {
    z-index: 1;
    backdrop-filter: blur(8px);
    background-color: rgba(247, 247, 247, 0.9);
    display: flex;
    position: fixed;
    height: 100vh;
    box-sizing: border-box;
    top: 0;
    left: 0;
    width: 100%;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center; }
    header.menu-open header-menu-item {
      width: 100%; }
      header.menu-open header-menu-item:not(:last-child) {
        margin: 0 auto 25px; }
      header.menu-open header-menu-item:not(.button-wrap) a {
        font-size: 14px; }
      header.menu-open header-menu-item.button-wrap {
        display: block;
        margin: 20px 0 0; }
        header.menu-open header-menu-item.button-wrap div {
          width: 120px;
          line-height: 34px;
          height: 36px;
          margin: 0 auto;
          font-size: 14px; }
          header.menu-open header-menu-item.button-wrap div:not(:last-child) {
            margin: 0 auto 20px; }

@media screen and (max-width: 1040px) {
  header-menu-button {
    display: block; }
  header-menu-item:not(.button-wrap) a.btn {
    margin: 0 auto; } }

page-wrapper {
  max-width: 1040px;
  width: 96%;
  margin: 0 auto;
  display: block;
  justify-content: space-between;
  align-items: center; }

page-container {
  display: block;
  padding: 124px 0 40px;
  box-sizing: border-box;
  min-height: calc(100vh - 269px); }
  page-container .page-title, page-container h1 {
    margin: 0 0 8px;
    font-size: 24px;
    font-weight: 700; }
  page-container .product-info {
    padding: 0 190px 0 0;
    min-width: 290px;
    width: 40%; }
  page-container .product-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 0; }
    page-container .product-item:not(:first-child) {
      border-top: 1px solid #D9D9D9; }
  page-container .product-image img {
    max-width: 275px;
    width: 100%;
    display: block;
    height: auto; }
  page-container .product-title {
    font-weight: 700;
    font-size: 24px;
    color: #0E0E0E;
    margin: 0 0 6px; }
  page-container .product-short-description {
    font-weight: 500;
    font-size: 16px;
    color: #0E0E0E;
    margin: 0 0 26px; }
  page-container .product-description {
    margin: 64px 0 0; }
    page-container .product-description h2 {
      font-size: 20px;
      font-weight: 600;
      margin: 0 0 6px; }
      page-container .product-description h2:not(:first-child) {
        margin: 64px 0 6px; }
    page-container .product-description p {
      font-size: 15px;
      font-weight: 500;
      line-height: 24px; }
  page-container .product-price {
    font-size: 42px;
    font-weight: 400; }
    page-container .product-price-box {
      margin: 0 0 28px;
      display: flex;
      align-items: center;
      gap: 10px; }
    page-container .product-price.old-price {
      font-size: 28px;
      position: relative; }
      page-container .product-price.old-price:after {
        content: '';
        width: 110%;
        height: 3px;
        background-color: #CE1B1B;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -5%;
        margin: auto;
        transform: rotate(-15deg); }
    page-container .product-price.new-price {
      font-size: 36px;
      color: #CE1B1B; }
  page-container .product-coming {
    color: #C36C6C;
    font-weight: 500;
    font-size: 24px;
    margin: 0 0 26px; }

.page-product page-wrapper {
  display: flex;
  align-items: flex-start; }

.page-product page-container .product-image img {
  max-width: 410px; }

.page-product page-container .product-info {
  padding: 0;
  max-width: 600px;
  width: 100%; }

.page-checkout page-container {
  padding: 124px 0 190px; }

.page-checkout .checkout-list {
  display: flex;
  justify-content: space-between;
  padding: 40px 0 15px;
  align-items: center;
  border-bottom: 1px solid #D9D9D9;
  flex-wrap: wrap; }

.page-checkout .coupon-box {
  width: 100%;
  text-align: right;
  font-size: 14px; }

.page-checkout .coupon-btn {
  cursor: pointer;
  text-decoration: underline; }

.page-checkout .coupon-code {
  display: flex;
  gap: 6px;
  justify-content: flex-end;
  align-items: center; }
  .page-checkout .coupon-code .code {
    font-weight: 600; }
  .page-checkout .coupon-code .clear-code {
    cursor: pointer;
    text-decoration: underline; }

.page-checkout .info-box {
  display: flex;
  align-items: center;
  gap: 10px; }
  .page-checkout .info-box .image img {
    max-width: 110px;
    width: 100%;
    display: block;
    height: auto; }
  .page-checkout .info-box .name {
    font-size: 18px;
    font-weight: 700;
    margin: 0 0 5px; }
  .page-checkout .info-box .short-description {
    font-size: 13px;
    font-weight: 500; }

.page-checkout .price-box {
  color: #000; }
  .page-checkout .price-box .product-price {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.01pxem; }
    .page-checkout .price-box .product-price-box {
      display: flex;
      align-items: center;
      gap: 10px;
      margin: 0 0 5px; }
    .page-checkout .price-box .product-price.old-price {
      font-size: 16px; }
    .page-checkout .price-box .product-price.new-price {
      color: #CE1B1B; }
  .page-checkout .price-box .vat {
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 14px;
    letter-spacing: 0.01em; }

.page-checkout .price-total {
  display: flex;
  align-items: center;
  gap: 10px; }
  .page-checkout .price-total span:first-child {
    font-size: 14px;
    font-weight: 500; }
  .page-checkout .price-total span:last-child {
    font-size: 23px;
    font-weight: 600;
    letter-spacing: 0.02em; }

.page-checkout .payment-box {
  margin: 60px 0 0;
  text-align: left; }
  .page-checkout .payment-box h2 {
    font-size: 15px;
    font-weight: 600;
    color: #0E0E0E;
    margin: 0 0 16px; }
  .page-checkout .payment-box .rc-tabs-tab ~ .rc-tabs-ink-bar {
    margin: 0; }
  .page-checkout .payment-box .rc-tabs-ink-bar {
    display: none; }
  .page-checkout .payment-box .rc-tabs-nav-operations {
    display: none !important; }
  .page-checkout .payment-box .rc-tabs-nav-list {
    display: flex;
    align-items: center; }
  .page-checkout .payment-box .rc-tabs-nav-wrap {
    margin: 0 0 60px; }
  .page-checkout .payment-box .rc-tabs-tab {
    width: 188px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    font-size: 16px;
    font-weight: 600;
    color: #191970;
    margin: 0 17px 0 0;
    cursor: pointer;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -ms-border-radius: 16px;
    -moz-border-radius: 16px;
    /* IE 8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
    /* IE 5-7 */
    filter: alpha(opacity=0.3);
    /* Netscape */
    -moz-opacity: 0.3;
    /* Safari 1.x */
    -khtml-opacity: 0.3;
    /* Good browsers */
    opacity: 0.3;
    box-shadow: 2px 2px 50px 0 rgba(5, 5, 100, 0.2);
    -webkit-box-shadow: 2px 2px 50px 0 rgba(5, 5, 100, 0.2);
    -moz-box-shadow: 2px 2px 50px 0 rgba(5, 5, 100, 0.2);
    transition: opacity 0.3s ease-in-out;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out; }
    @media (hover: hover) {
      .page-checkout .payment-box .rc-tabs-tab:hover {
        opacity: 1; } }
    .page-checkout .payment-box .rc-tabs-tab:nth-last-child(-n+2) {
      margin: 0; }
    .page-checkout .payment-box .rc-tabs-tab-active {
      opacity: 1; }
    .page-checkout .payment-box .rc-tabs-tab > div {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 7px 5px; }
      .page-checkout .payment-box .rc-tabs-tab > div svg {
        margin: 0 15px 0 0;
        display: block;
        height: 100%;
        width: auto; }
      .page-checkout .payment-box .rc-tabs-tab > div span {
        color: #0E0E0E;
        font-size: 14px;
        font-weight: 600;
        width: fit-content;
        line-height: 19px; }
  .page-checkout .payment-box .rc-tabs-content-holder {
    max-width: 400px; }
  .page-checkout .payment-box form.stripe-form label input,
  .page-checkout .payment-box form.stripe-form label .StripeElement {
    display: block;
    margin: 0 0 60px 0;
    max-width: 500px;
    padding: 8.6px 14px;
    outline: 0;
    border-radius: 4px;
    background: white;
    font-size: 14px;
    border: 1px solid #cecece;
    letter-spacing: 0.02em;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -ms-border-radius: 6px;
    -moz-border-radius: 6px;
    transition: none 0.3s ease-in-out;
    -webkit-transition: none 0.3s ease-in-out;
    -moz-transition: none 0.3s ease-in-out; }
  .page-checkout .payment-box form.stripe-form .field-row {
    margin: 0 0 40px; }
    .page-checkout .payment-box form.stripe-form .field-row input {
      min-height: 36px;
      border-radius: 6px;
      -webkit-border-radius: 6px;
      -ms-border-radius: 6px;
      -moz-border-radius: 6px; }

.page-download page-container, .page-payment page-container {
  display: flex;
  align-items: center; }

.page-download page-wrapper, .page-payment page-wrapper {
  text-align: center; }
  .page-download page-wrapper h1, .page-payment page-wrapper h1 {
    font-size: 24px;
    font-weight: 700; }
  .page-download page-wrapper .description, .page-payment page-wrapper .description {
    margin: 20px 0 50px;
    font-size: 16px;
    font-weight: 500; }
  .page-download page-wrapper .btn, .page-payment page-wrapper .btn {
    width: 220px; }
  .page-download page-wrapper .additional-data, .page-payment page-wrapper .additional-data {
    font-size: 14px;
    font-weight: 500;
    color: #9B9B9B; }
    .page-download page-wrapper .additional-data a, .page-payment page-wrapper .additional-data a {
      color: #605AF4;
      text-decoration: underline; }

@media screen and (max-width: 1024px) {
  page-container .product-info {
    padding: 0 100px 0 0; }
  .page-checkout page-container {
    padding: 124px 0 40px; } }

@media screen and (max-width: 768px) {
  page-container .product-info {
    padding: 0 80px 0 0; }
  .page-product page-container .product-info {
    max-width: 450px; } }

@media screen and (max-width: 520px) {
  page-container .product-item {
    display: block;
    text-align: center; }
  page-container .product-image {
    margin: 0 0 40px; }
    page-container .product-image img {
      max-width: 100%;
      margin: 0 auto; }
  page-container .product-info {
    width: 100%;
    padding: 0; }
  page-container .product-price-box {
    justify-content: center; }
  .page-product page-wrapper {
    display: block; }
  .page-product page-container .product-info {
    text-align: center;
    max-width: inherit; }
  .page-product page-container .product-description {
    text-align: left; } }

footer {
  background-color: #171058;
  padding: 72px 0 58px;
  position: relative; }
  footer-wrapper {
    display: flex;
    max-width: 1040px;
    width: 96%;
    margin: 0 auto;
    align-items: flex-start;
    justify-content: space-between;
    color: #fff;
    font-size: 14px;
    letter-spacing: 0.02em; }
  footer .item.col-1 {
    width: 420px; }
    footer .item.col-1 .logo {
      margin: 0 0 80px; }
      footer .item.col-1 .logo svg {
        display: block;
        height: auto;
        width: 190px; }
    footer .item.col-1 .description {
      margin: 20px 0 58px;
      line-height: 1.6;
      font-size: 14px; }
  footer .item.col-3 {
    width: 280px; }
    footer .item.col-3 .text {
      line-height: 25px; }
  footer .copy-right-mobile {
    display: none; }
  footer .footer-menu li:not(:last-child) {
    margin-bottom: 13px; }
  footer .footer-menu li a {
    font-weight: 400;
    /* IE 8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
    /* IE 5-7 */
    filter: alpha(opacity=1);
    /* Netscape */
    -moz-opacity: 1;
    /* Safari 1.x */
    -khtml-opacity: 1;
    /* Good browsers */
    opacity: 1;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease; }
    @media (hover: hover) {
      footer .footer-menu li a:hover {
        /* IE 8 */
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
        /* IE 5-7 */
        filter: alpha(opacity=0.8);
        /* Netscape */
        -moz-opacity: 0.8;
        /* Safari 1.x */
        -khtml-opacity: 0.8;
        /* Good browsers */
        opacity: 0.8; } }
    footer .footer-menu li a.bold {
      font-weight: 600; }
  footer .social:not(:first-child) {
    margin: 9px 0 0; }

@media screen and (max-width: 1040px) {
  footer {
    padding: 60px 0 40px; }
    footer-wrapper {
      display: block;
      text-align: center; }
    footer .item {
      width: 100% !important; }
      footer .item.col-1 .logo {
        margin: 0 0 30px; }
        footer .item.col-1 .logo svg {
          max-width: 170px; }
        footer .item.col-1 .logo a {
          display: inline-block; }
      footer .item.col-2 {
        margin: 0 0 30px; }
    footer .social-icon-box {
      justify-content: center; }
    footer .copy-right {
      display: none; }
      footer .copy-right-mobile {
        margin: 30px 0 0;
        display: block; } }
